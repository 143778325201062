import './assets/stylesheets/Styles.scss';
import ENLogo from './assets/images/logos/en-logo.png';
import FNFLogo from './assets/images/logos/fnf-logo.svg';

export default function Footer() {
    return(
        <footer>
            <div className="footer-container">
                <div className='footer-logos'>
                    <div className="en-logo"><a href="https://economynext.com/" target="_blank"><img src={ENLogo} /></a></div>
                    <div className="fnf-logo"><a href="https://www.freiheit.org/" target="_blank"><img src={FNFLogo} /></a></div>
                </div>
                <div className='copyright'>© 2023 Echelon Media. All Rights Reserved</div>
            </div>
        </footer>
    );
}