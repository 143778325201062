import './assets/stylesheets/Styles.scss';
import Chapter1GraphOne from './assets/images/chapters/chapter-one-graph-one.svg';
import Chapter1GraphTwo from './assets/images/chapters/chapter-one-graph-two.svg';
import Chapter1GraphThree from './assets/images/chapters/chapter-one-graph-three.svg';
import Chapter1GraphFour from './assets/images/chapters/chapter-one-graph-four.svg';
import Chapter4GraphOne from './assets/images/chapters/chapter-four-graph-one.svg';
import Chapter4GraphTwo from './assets/images/chapters/chapter-four-graph-two.svg';
import Chapter6Graph from './assets/images/chapters/chapter-six-graph.svg';
import Chapter8Graph from './assets/images/chapters/chapter-eight-graph.svg';
import Chapter2GraphOne from './assets/images/chapters/chapter-two-graph-one.svg';
import Chapter2GraphTwo from './assets/images/chapters/chapter-two-graph-two.svg';
import Chapter2GraphThree from './assets/images/chapters/chapter-two-graph-three.svg';
import Chapter2GraphFour from './assets/images/chapters/chapter-two-graph-four.svg';
import Chapter2GraphFive from './assets/images/chapters/chapter-two-graph-five.svg';
import Chapter2GraphSix from './assets/images/chapters/chapter-two-graph-six.svg';
import Chapter2GraphSeven from './assets/images/chapters/chapter-two-graph-seven.svg';
import Chapter2GraphEight from './assets/images/chapters/chapter-two-graph-eight.svg';
import Chapter2GraphNine from './assets/images/chapters/chapter-two-graph-nine.svg';
import Chapter2GraphTen from './assets/images/chapters/chapter-two-graph-ten.svg';
import Chapter10GraphOne from './assets/images/chapters/chapter-ten-graph-one.svg';
import Chapter10GraphTwo from './assets/images/chapters/chapter-ten-graph-two.svg';
import Chapter10GraphThree from './assets/images/chapters/chapter-ten-graph-three.svg';
import Chapter10GraphFour from './assets/images/chapters/chapter-ten-graph-four.svg';
import Chapter11Graph from './assets/images/chapters/chapter-eleven-graph.svg';

export default function Chapters() {

	const accordion = (id) => {
		const label = document.querySelector('#'+id);
		let parent = label.parentNode;
		// let list = label.nextSibling;
		
		if (parent.classList.contains('is-open')) {
			parent.classList.remove('is-open');
			label.classList.remove('plus-selected');
		}
		else {
			parent.classList.add('is-open');
			label.classList.add('plus-selected');
		}
	}

	return (
		<section className="nav-wrap">
			<nav className="acnav" role="navigation">
				<ul className="acnav__list acnav__list--level1">
					{/* about the author */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_about' onClick={() => accordion('chapter_about')}>About the Author<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-about-brief">
								<h2>Sanduni Wickramasinghe</h2>
								<p>Consultant - Information Privacy and Technology Law | LLM (Leiden), LLB (Colombo), B.Sc. in IT (Middlesex),<br></br> Attorney-at-Law (Sri Lanka), IAPP Fellow of Information Privacy (FIP), CIPP/E, CIPM, Member of the Data Protection Law Drafting Committee, Sri Lanka (2019-2021).<br></br> info@privacy.lk | The author acknowledges the research assistance provided by Ms. Shawnae Algama LLB (Colombo) in the preparation of this whitepaper.</p>
							</div>
						</ul>
					</li>
					{/* introduction */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_one' onClick={() => accordion('chapter_one')}>Introduction<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-one-brief">
								<h2>Personal Data Protection Act No.9 of 2020</h2>
								<img src={Chapter1GraphOne} className='chapter-img' />
								<img src={Chapter1GraphTwo} className='chapter-img' />
							</div>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_one_one' onClick={() => accordion('chapter_one_one')}>Application and Exclusions<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<img src={Chapter1GraphThree} className='chapter-img' />
											<h2 className='important-txt'>IMPORTANT</h2>
											<p><b>PDPA applies to:</b></p>
											<ul>
												<li>All personal data that continues to be processed when the PDPA (or parts thereof) is in effect.</li>
												<li>All personal data held in physical form or manually handled.</li>
												<li>All personal data held in digital form or handled electronically.</li>
												<li>Personal data belonging to persons alive or deceased.</li>
											</ul>
											<p><b>PDPA does not apply to:</b></p>
											<ul>
												<li>Data that cannot identify or not capable of identifying a human being.</li>
												<li>Anonymous data including statistical data</li>
												<li>Data relating to legal persons (i.e. companies)</li>
											</ul>
											<p><b>Co-Existence with other laws:</b></p>
											<ul>
												<li>Operation of PDPA does not invalidate processing done according to other written laws (i.e. Banking Act, Financial Transactions Reporting Act, Telecommunications Act, Right to Information Act etc.)</li>
												<li>However, if there is any inconsistency particularly with regards to the <b>protection of personal data</b>, the PDPA will override such law.</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_one_two' onClick={() => accordion('chapter_one_two')}>Key Definitions<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<img src={Chapter1GraphFour} className='chapter-img' />
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{/* principles of processing */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_two' onClick={() => accordion('chapter_two')}>The Principles of Processing<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-two-brief">
								<p>The Principles of Processing found in Part 1 of the PDPA applies only to controllers. Part 1 therefore containes 8 main principles:</p>
								<img src={Chapter2GraphOne} className='chapter-img' />
							</div>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_one' onClick={() => accordion('chapter_two_one')}>Lawfulness - Section 05<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<img src={Chapter2GraphTwo} className='chapter-img' />
											<p><b>Schedule 1 and 2:</b><br></br>A controller’s identified purpose in processing personal data must correspond to a lawful ground recognized under Schedule 1 or Schedule 2 if the controller is processing special categories of personal data. A controller need not satisfy multiple grounds recognized in Schedule 1 or 2. Controller must rely on the most suitable ground based on the nature and purposes of his processing activity.</p>
											<img src={Chapter2GraphThree} className='chapter-img' />
											<img src={Chapter2GraphFour} className='chapter-img' />
											<img src={Chapter2GraphFive} className='chapter-img' />
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_two' onClick={() => accordion('chapter_two_two')}>Defining a Purpose (Purpose Specification) - Section 6<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>There is an obligation on the controller to process personal data for a purpose which should satisfy the following three conditions:</p>
											<img src={Chapter2GraphSix} className='chapter-img' />
											<div className='chapters-example'>EXAMPLE</div>
											<p><em>A bank records personal data such as income and contact information of individuals for the purpose of providing vehicle loans. Communicating such information to the marketing department of the bank for the purpose of promoting products based on the income levels of the individuals would be incompatible with the original purpose of data collection and would require a new and separate legal basis.</em></p>
											<p><em>The registration forms for an event require the personal data of the attendees including food preferences such as vegetarian and non-vegetarian. If this data is transmitted to a direct marketing company for the purpose of running targeted campaigns on alternative-meat products, it would be incompatible with the original purpose of data collection and would require a new and separate legal basis.</em></p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_three' onClick={() => accordion('chapter_two_three')}>Limiting to the Purpose (Data Minimization) - Section 7<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>It is necessary to ensure that the personal data processed for a particular purpose satisfy the following three conditions:</p>
											<img src={Chapter2GraphSeven} className='chapter-img' />
											<div className='chapters-example'>EXAMPLE</div>
											<p><em>A company filling the vacancy of receptionist requires details about the personal religious beliefs of such prospective employees, amongst other information. Such information may be irrelevant, excessive, and disproportionate to the purpose of recruitment.</em></p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_four' onClick={() => accordion('chapter_two_four')}>Accuracy - Section 8<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>A controller must ensure that the personal data that he processes is accurate and up to date. He must take every reasonable measure to ensure that any inaccurate or obsolete data is either erased or rectified without any delay. Accordingly, a controller should periodically review the accuracy of the personal data he holds.</p>
											<p>However, just because a personal data record has been updated, it may not mean that the historical record is inaccurate. For example, a Bank’s customer may have changed his residence from Colombo to Kandy. Retaining his current address as Colombo would be inaccurate. However, retaining a record indicating that the said customer used to live in Colombo would be accurate.</p>
											<div className='chapters-example'>EXAMPLE</div>
											<p><em>A company obtains email address from a customer named John Doe to maintain customer communication. The customer’s email is johndoe@gmail.com.</em></p>
											<p><em>However, during the data entry process, the company’s employee makes a typographic error and enters the email address as johndeo@gmail.com. Using the email address johndeo@gmail.com is inaccurate and may even be classified as a data breach.</em></p>
											<p><em>The situation could have been avoided if the company had taken steps to independently verify if the email address of Mr. John Doe at the point of data entry such as sending a verification link to verify the email address and automatically stop using any unverified email address.</em></p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_five' onClick={() => accordion('chapter_two_five')}>Data Retention - Section 9<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>A controller must keep personal data only as long as it is required to achieve the stated purposes. However, personal data can be kept for a longer period if such data can no longer identify the data subject to whom it relates to.</p>
											<p>A controller may keep personal data even after the purpose is fulfilled for archiving purposes in the public interest, scientific research, historical research or statistical purposes.</p>
											<p>Therefore, a controller must periodically review the personal data he holds and either erase or anonymize the personal data when it is no longer needed to fulfil the stated purposes.</p>
											<p>This can be better and effectively handled by having a clear data retention policy for the controller’s enterprise detailing the periods of retention and erasure. This would lessen the burden of responding to access requests regarding retention or erasure from the data subjects.</p>
											<p>Controllers should always favour specific retention periods in line with their purposes of processing and deter from retaining data for indefinite periods of time.</p>
											<p>A controller’s data retention obligation must extend to his processors as well. A controller must ensure that the processors do not retain data once the purpose is fulfilled or when the services have ceased.</p>
											<div className='chapters-example'>EXAMPLE</div>
											<p><em>A company retaining the curriculum vitae of candidates who were not successful in being offered a contract of employment with the company may no longer be relevant if the vacancy in question has already been filled.</em></p>
											<p><em>A telecom operator may process a subscriber’s personal data such as name, address and identification data for the purpose of providing services and for regulatory reasons. The telecom operator may retain this data as long as the subscriber remains a customer of the operator. Even after a subscriber ceases to be one, the operator may still retain some of this information to meet certain operational or regulatory requirements for a defined period of time.</em></p>
											<p><em>A hospital may retain medical records of cancer patients for 10 years in aggregate form for the purposes of scientific and medical research in developing a new drug to cure cancer.</em></p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_six' onClick={() => accordion('chapter_two_six')}>Integrity and Confidentiality - Section 10<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>A controller must ensure integrity and confidentiality of the personal data that he processes. A controller must ensure that all processors who process personal data on his behalf also ensure integrity and confidentiality of the personal data.</p>
											<p>A controller must use appropriate technical and organisational measures to ensure that the personal data is processed securely and confidentially. These measures may include using encryption, pseudonymisation, anonymisation, access controls, and other measures which the DPA may direct in the future.</p>
											<p>The objective of any technical or organisational measure should be twofold:</p>
											<ul>
												<li>Prevent unauthorized or unlawful processing of personal data and</li>
												<li>Prevent loss, destruction or damage of personal data (including intentional or accidental acts)</li>
											</ul>
											<p>Use of appropriate technical or organisational measures will assist a controller to duly detect, respond and contain data breach incidents.</p>
											<div className='chapters-example'>EXAMPLE</div>
											<p><em>A bank by default implementing two-factor authentication measures when a customer logs into their account online.</em></p>
											<p><em>A company defining privileges as to who can access and modify customer data and making sure all accesses to customer databases are recorded and monitored.</em></p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_seven' onClick={() => accordion('chapter_two_seven')}>Transparency - Section 11<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Under the PDPA, to satisfy the transparency obligation, a controller must do the following:</p>
											<ul>
												<li>Provide to the data subjects the information referred to in Schedule 5 of the PDPA (generally in the form of an accessible privacy/data protection notice), AND</li>
												<li>Provide to the data subjects the information regarding any decision taken by the controller following a request made by a data subject to exercise his rights under Part II of the PDPA (i.e. access rights, right to withdraw consent, right to object, right to rectification, right to erasure and right to request review of a decision made using automated decision making process).</li>
											</ul>
											<img src={Chapter2GraphEight} className='chapter-img' />
											<div className='important-txt'>EXEMPTIONS</div>
											<b><em><p>The requirements under items (1), (2), (3) and (4) above may not apply in situations where:</p>
											<ol>
												<li>The controller can establish that the data subject has already been provided with the information or was made aware of it and therefore there was no requirement to inform the data subject subsequently,<br></br>OR</li>
												<li>Providing all that information is impossible or involves a disproportionate effort on the part of the controller, OR providing all that information would impair the objectives of the processing activity or make them impossible to achieve. In such cases, the controller must be able to establish the above circumstances to the satisfaction of the DPA when required, and must publish the required information at a publicly accessible place and take appropriate measures to protect the rights and freedoms of data subjects protected by the PDPA or any other law,<br></br>OR</li>
												<li>Obtaining personal data from the data subject or disclosing the said data to a third party is set out in a statute (law) which the controller has to comply with. However, such statute/law must provide for suitable measures to safeguard the data subject’s rights and freedoms,<br></br>OR</li>
												<li>The controller has a professional relationship with the data subject where the exchange of personal data is considered privileged and confidential including abiding by statutory obligations of secrecy by the controller.</li>
											</ol></em></b>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_two_eight' onClick={() => accordion('chapter_two_eight')}>Accountability - Section 12<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>The Accountability principle requires a controller to have in place a data protection management program within its organisation that can meet the following requirements:</p>
											<img src={Chapter2GraphNine} className='chapter-img' />
											<p><b><em>How to design a data protection management program:</em></b></p>
											<img src={Chapter2GraphTen} className='chapter-img' />
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{/* processors and their role */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_three' onClick={() => accordion('chapter_three')}>Processors and Their Role<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-three-brief">
								<p>A processor is an entity that carries out a processing activity on behalf of the controller. A processor has certain obligations to comply with under the PDPA. Sometimes, when a controller does not have a particular expertise or capacity to carry out a processing activity, he can engage the services of a third-party entity known as a processor to carry out those particular processing activities on the controller’s behalf.</p>
								<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><em><b>A controller must at all times have a contract with the processor. The contractor-processor relationship may also be set out in a statute/legislation or similar instrument particularly in the case of public authorities acting as controllers and processors.</b></em></p>
							</div>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_three_one' onClick={() => accordion('chapter_three_one')}>Factors a Controller Must Consider Prior to and When Selecting a Processor<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">								<li>
										<div className="chapter-content-area">
											<p className='highlighted-text highlighted-text-lavender'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.7 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0z"/></svg> Use processors who are capable of providing appropriate technical and organizational measures to give effect to the PDPA as it applies to the respective controller.</p>
											<p className='highlighted-text highlighted-text-lavender'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z"/></svg> Use processors who can provide protection of rights of the data subjects under the PDPA as far as it applies to the respective controller.</p>
											<p className='highlighted-text highlighted-text-lavender file-icon'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V160H256c-17.7 0-32-14.3-32-32V0H64zM256 0V128H384L256 0zM112 256H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z"/></svg> Ensure each and every processor of the controller is bound by contract or other written law which will set out the following: subject matter of the processing, duration of processing, nature and purpose of processing, type of personal data that will be processed, categories of data subjects concerned, and obligations of the controller (under the PDPA and other laws)</p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_three_two' onClick={() => accordion('chapter_three_two')}>How to Choose the Right Processor<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Considering the above obligations, what can the controller do to make the best decision?</p>
											<p>One way is at the point of selecting a processor, where the controller undertakes an extensive evaluation of the processor’s readiness to support the controller’s respective compliance requirements under the PDPA. A controller can include a detailed set of requirements in the RFPs or tender documents so that a processor can proactively respond to them. Based on their feedback, the controller will be in a position to diligently evaluate the capacity of the processor.</p>
											<p>For existing processors, it may be necessary to revisit the existing contractual relationships and include the corresponding obligations to those agreements.</p>
											<p>Past data breach incidents may be a red flag when selecting a processor. However, offering PDPA compliance as a service would be a plus point for a processor.</p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_three_three' onClick={() => accordion('chapter_three_three')}>Obligations Specific to Processors<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>If you are a processor providing processing services on behalf of a controller, the PDPA has some specific obligations that require your attention and compliance.</p>
											<p>Whilst a processor is exempted from complying with most of the salient features of the PDPA, there are certain components that may still trigger liability of a processor under the PDPA.</p>
											<div className='highlighted-text-container'>
												<p className='highlighted-text highlighted-text-lavender'>Comply with the written instructions of the controller. This may generally be in the form of a contract or other binding document.</p>
												<p className='highlighted-text highlighted-text-lavender'>Make sure its personnel (employees, agents, contractors etc.) are bound by contractual confidential and secrecy obligations + technical and organizational measures.</p>
												<p className='highlighted-text highlighted-text-lavender'>Facilitate controller to conduct compliance audits and inspections upon request.</p>
												<p className='highlighted-text highlighted-text-lavender'>Erase or return data to the controller once services are completed.</p>
												<p className='highlighted-text highlighted-text-lavender'>Be responsible to the controller for the activities of any sub-processor.</p>
											</div>
											<p>A processor may engage the services of another third party, known as a sub-processor, to perform a task on behalf of the controller. However, the original processor remains liable to the acts or omissions of its sub-processor(s). A processor must always have a legally binding agreement with all its sub-processors which corresponds to its own obligations towards the controller.</p>
										</div>	
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{/* rights of data subjects */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_four' onClick={() => accordion('chapter_four')}>Rights of Data Subjects and THEIR Enforcement<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-four-brief">
								<p>One of the key features of the PDPA is its recognition of data subject rights when it comes to personal data processing. Part II of the PDPA contains six (6) such rights. They are as follows:</p>
								<img src={Chapter4GraphOne} className='chapter-img' />
								<p>The exercise of Access Right, Withdrawal of Consent, Right to Object, Rectification Right and Right to Erasure are subject to the exemptions given under section 17 as follows. Section 17 does not apply to Automated Decision-Making provisions</p>
								<img src={Chapter4GraphTwo} className='chapter-img' />
								<p><b>Rights under Part II can be exercised by</b></p>
								<ul>
									<li>Parents/legal guardian of a minor</li>
									<li>Legal guardian/court appointed administrator of a physically or mentally unfit data subject</li>
									<li>by a person authorized in writing by the data subject</li>
									<li>by an heir of a deceased data subject’s right within 10 years of demise</li>
								</ul>
							</div>
						</ul>
					</li>
					{/* role of dpo */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_five' onClick={() => accordion('chapter_five')}>Role of DPO: Appointment and Functions<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-five-brief">
								<p>A Data Protection Officer known as DPO, is someone who will be in-charge of ensuring that a controller or processor meets their respective obligations under the PDPA. Whilst the ultimate responsibility to comply with the PDPA is on the controllers and processors, a DPO is meant to assist in the process.</p>
							</div>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_five_one' onClick={() => accordion('chapter_five_one')}>Mandatory Appointments<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>A DPO must be appointed under section 20 of the PDPA if a controller or processor falls into any one of the following three categories:</p>
											<ul>
												<li>Controller or processor is a ministry, government department or public corporation, except for judiciary acting in their judicial capacity.</li>
												<li>
													If the controller or the processor is processing personal data that is:
													<ul>
														<li>regularly and systematically monitoring data subjects or</li>
														<li>processing special categories of personal data according to a prescribed scale and magnitude which will be stipulated by the DPA.</li>
													</ul>
												</li>
												<li>If a controller’s or processor’s processing results in a risk of harm to the rights of data subjects protected by the PDPA.</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_five_two' onClick={() => accordion('chapter_five_two')}>Qualifications of a DPO<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>A DPO may be an employee of the organization, or an outsourced entity which provides DPO-as-a-Service to various organizations. In any event, a DPO must possess the following qualifications according to the PDPA:</p>
											<ul>
												<li>Academic & professional qualifications which will be stipulated by the DPA</li>
												<li>Academic background, knowledge & technical skill on data protection</li>
												<li>Competency & capacity to implement strategies and mechanisms to respond to inquiries and incidents</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_five_three' onClick={() => accordion('chapter_five_three')}>Responsibilities of a DPO<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Whether the DPO is in-house or outsourced, a DPO must deliver the following responsibilities according to the PDPA:</p>
											<ul>
												<li>Advise controller or processor and their respective employees on compliance requirements under the PDPA. Accordingly, a DPO must be in a position to understand the legal requirements of the PDPA and translate them to the organization’s operational and technical teams.</li>
												<li>Ensure that the controller or processor complies with their respective obligations under the PDPA. This may include reviewing policy documents, processes, and new and existing products and services of the controller or processor.</li>
												<li>Capacity building of staff. This may entail conducting training and awareness sessions including continued privacy training.</li>
												<li>Advise controllers when it comes to carrying out Data Protection Impact Assessments. However, carrying out a DPIA should not be the sole responsibility of a DPO. A DPO can direct the business units on the method and process of carrying out DPIAs and review the outcome of such assessments to take appropriate measures to address any issues identified in such assessments.</li>
												<li>Cooperate with the DPA where instructions and/or directives are issued against the controller or processor.</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_five_four' onClick={() => accordion('chapter_five_four')}>The Ideal DPO<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Must be independent and should be able to exercise his functions independently without fear of being penalized for his decisions. DPO must ideally report to the Board and/or CEO directly. DPO should not be dictated on to how to do his job.</p>
											<p>Must not have conflict of interest or be combined with the role of another position within the organization. i.e. CIO or CISO should not be DPO because a DPO must be able to review the activities of the IT department or the information security department.</p>
											<p>Must be allowed adequate resources including physical, personnel and financial resources to meet the obligations. This also includes support and assistance from different departments and all levels within the organization. It is important that the senior management facilitate the establishment of a DPO.</p>
											<p>DPO must be a data protection expert. An organization shouldn’t appoint someone who has only legal expertise but lacks ability to operationalize the compliance requirements under the PDPA. Similarly, a purely technical expert may also not be a wise choice, as a DPO must be able to understand and interpret legal provisions of the PDPA. In any event, a DPO must be a person of integrity and capability.</p>
											<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>A single DPO may be appointed for a group of companies, provided such DPO can perform the above requirements for each entity.</em></b></p>
											<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>If the entity is a public authority, the PDPA allows appointment of a single DPO taking into account their organizational structure.<br></br>(For example: a single DPO may be appointed for all public authorities performing social welfare functions.)</em></b></p>
											<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>Where a DPO is appointed, his/her contact details must be published to the public and communicated to the DPA as well.</em></b></p>
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{/* data protection impact assessments */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_six' onClick={() => accordion('chapter_six')}>Data Protection Impact Assessments<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-six-brief">
								<p>A DPIA is an assessment to ascertain the impact of certain processing activities on the principles of processing and the rights of data subjects. The DPA may provide further guidance in the future.</p>
							</div>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_six_one' onClick={() => accordion('chapter_six_one')}>Mandatory Grounds<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>According to section 24 of the PDPA, a DPIA must be carried out by a controller before he carries out any one of the following processing activities:</p>
											<ul>
												<li>A systematic and extensive evaluation of personal data or special categories of personal data including profiling <em>(example: by a Supermarket building customer profiles based on their loyalty customers’ purchasing patterns for marketing needs, a telecom operator analyzing a customer’s data usage to promote specific packages)</em>, or</li>
												<li>A systematic monitoring of publicly accessible areas or telecommunication networks <em>(example: use of CCTV monitoring in a public place to monitor visitors)</em>, or</li>
												<li>A processing activity to be determined by the DPA in the future.</li>
											</ul>
											<p>In addition, a fresh DPIA must be carried out when there is a change to the technology, methodology or process used to carry out a processing activity for which a DPIA has been carried out.</p>
										</div>	
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_six_two' onClick={() => accordion('chapter_six_two')}>Methodology<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<ul>
												<li>Identify the information flow of the processing activity. This would include identifying the origins and destinations, processes, purposes and workflows of the processing activity.</li>
												<li>Identify possible risks. Risks refer to the likelihood and severity of any negative impact on the individual concerned and sometimes, society in general. Risk will depend on the nature, scope, context and purpose of processing. Risks can be strategic or operational.</li>
												<li>Identify means of mitigating risks. Identify the safeguards to be put in place (if required).</li>
												<li>Consult the DPA if any risk cannot be mitigated.</li>
												<li>Record the findings in detail and review and sign off by all internal stakeholders.</li>
											</ul>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_six_three' onClick={() => accordion('chapter_six_three')}>DPIA PROCESS<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<img src={Chapter6Graph} className='chapter-img' />
										</div>	
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{/* data breach notifications */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_seven' onClick={() => accordion('chapter_seven')}>Data Breach Notifications<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-seven-brief">
								<ul>
									<li>Personal data breach means, any act or omission that results in accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored or otherwise processed.</li>
									<div className='chapters-example'>EXAMPLE</div>
									<li className='no-list-style'>
										<ul>
											<li>A hacker gaining access to a controller’s servers,</li>
											<li>An employee leaking sensitive customer information for commercial or other advantage,</li>
											<li>An employee accidentally erasing personal data stored in the servers of the controller.</li>
											<li>An employee of a bank negligently entering incorrect email address of a customer in the bank’s database which leads to the client’s bank statements being emailed to a third party.</li>
										</ul>
									</li>
									<li>Controller must notify the DPA of any breaches within the prescribed time. The time to inform is not yet provided in the Act. In other countries, this window varies from 48 to 72 hours from the time the breach was known to the controller.</li>
									<li>Inform data subjects if required to do so by the DPA. In other jurisdictions, data subjects may have to be informed of the breach so they can take measures to safeguard themselves such as changing passwords and being vigilant of any suspicious activity.</li>
									<li>The form, and manner of making such notification, and the information which shall be provided in such notification relating to the data breach are to be prescribed by Rules. The DPA will issue required guidelines in the future on how to inform the DPA and/or data subjects of a breach and what items should be there in such notice.</li>
								</ul>
								<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>It is essential that controllers and processors have in place a data breach management program within their organisations to detect, contain and respond to data breach incidents in a timely and effective manner. It is also necessary that these obligations are reflected in any contractual arrangement between the controller and processor so that any breach on the part of a processor is duly notified to the respective controller.</em></b></p>
							</div>
						</ul>
					</li>
					{/* solicited messaging */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_eight' onClick={() => accordion('chapter_eight')}>Solicited Messaging<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-eight-brief">
								<p>Solicited messages means the sending of messages to a data subject by post, through telecommunication services, electronic means or other similar methods, by a controller, with the consent of the data subject.</p>
								<p>A controller need not require prior consent to send every type of message. The PDPA requires data subject’s consent to send messages which promote a good or service of a controller or any third party or which promote any person, entity or organization including the controller.</p>
								<img src={Chapter8Graph} className='chapter-img' />
								<p>A message may include any written, electronic, oral, pictorial or video message. This may include not only SMSs, post or emails, but also automated or manned voice calls, flyers and messages sent through messaging platforms such as Whatsapp, Viber, Facebook Messenger etc.</p>
								<p>When a controller engages in sending solicited messages:</p>
								<ul>
									<li>he must ensure that consent is obtained per the conditions given under Schedule 3 of the PDPA,</li>
									<li>inform the data subject regarding the nature of the message and identity of the controller (the identity/source of the message should not be masked),</li>
									<li>inform the data subjects at the time of collecting consent, that he/she can opt out from receiving the messages at any time, and</li>
									<li>inform in the same message how to opt out. Opting out should be provided free of charge. For example, if a data subject requests to opt out using an SMS or phone call, the data subject should not be charged for that SMS or phone call. The cost should be borne by the controller.</li>
								</ul>
								<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>Generally, controllers may use third parties to provide them with databases of phone numbers or email addresses to conduct marketing promotions. These third-party aggregators may send out messages on behalf of a controller. In such a situation, the controller must ensure that the recipients of the messages have consented to receiving messages from the controller, and as its processor, the respective third-party would be responsible to ensure consent is duly obtained and managed.</em></b></p>
							</div>
						</ul>
					</li>
					{/* cross border data processing */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_nine' onClick={() => accordion('chapter_nine')}>Cross Border Data Processing<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-nine-brief">
								<p>Cross-border data processing means, where processing of personal data takes place outside of Sri Lanka. This may include situations such as data that is collected in Sri Lanka is stored outside of Sri Lanka, or data stored in Sri Lanka is analyzed in another country. As processing means a variety of things under the PDPA, it is important that a controller knows if any part of the processing is taking place outside Sri Lanka.</p>
								<p>Cross-border data processing may take place between controllers, controllers and processors or processors and sub-processors.</p>
							</div>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_nine_one' onClick={() => accordion('chapter_nine_one')}>Is There a Localization Requirement?<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Data localization means, processing personal data only in Sri Lanka. Generally, the PDPA does not mandate a blanket localization requirement. A limited localization requirement is applicable to public authorities, which includes government ministries, departments, provincial councils, local authorities, statutory bodies or any institution established by any written law.</p>
											<p>However, such public authority may be allowed to process personal data outside Sri Lanka if the DPA in consultation with such public authority and any relevant regulatory or statutory body, classify the personal data that is being processed and identify which classes of data may be processed at a location outside of Sri Lanka. In any case such location must have received an adequacy decision.</p>
											<p>In this case the public authority may be a controller or a processor.</p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_nine_two' onClick={() => accordion('chapter_nine_two')}>What is Adequacy Decision?<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>An adequacy decision is where the Minister in charge of the subject of personal data protection decides in consultation with the DPA, that a country outside Sri Lanka guarantees an adequate level of protection to personal data in par with the PDPA.</p>
											<p>Prior to arriving at this decision, the relevant laws relating to personal data protection and its enforcement need to be considered in detail. Any adequacy decision will be subjected to periodic monitoring.</p>	
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_nine_three' onClick={() => accordion('chapter_nine_three')}>Can a Controller or Processor, Who is Not a Public Authority, Process Data Outside Sri Lanka?<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Yes, if the processing takes place under any of the following circumstances:</p>
											<ol>
												<li>The processing takes place at a location/country which has received an adequacy decision under the PDPA, or</li>
												<li>If the third country does not have an adequacy decision, then such controller or processor adopts such instruments which will be stipulated by the DPA in the future. Such instruments would provide a mechanism to ensure binding and enforceable commitments from the recipient in the third country to meet certain obligations of the PDPA. These instruments may be in the form of standard contractual clauses, codes of conduct, and binding corporate rules, as found under the GDPR.</li>
											</ol>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_nine_four' onClick={() => accordion('chapter_nine_four')}>Are There Any Exemptions Which a Controller or Processor Can Rely on?<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Yes, if a controller or processor is not a public authority, he may rely on several exemptions recognized in the PDPA to process personal data at a location outside Sri Lanka even if such location has not received an adequacy decision or there is no instrument entered into between the controller/processor with the third-country recipient.</p>
											<p>The exemptions are:</p>
											<ul>
												<li>Data subject has explicitly consented to the proposed processing outside Sri Lanka after being informed of the risks including the absence of adequacy decision or appropriate safeguards, or</li>
												<li>The transfer of personal data is necessary to perform a contract between the data subject and the controller or to implement any pre-contractual measures at the request of the data subject (example: a data subject located in Sri Lanka is signing up to a social media platform based in another country and all processing activities are managed outside Sri Lanka), or</li>
												<li>The transfer is necessary for the establishment, exercise or defense of legal claims relating to a data subject, or</li>
												<li>Transfer is necessary for reasons of public interest. Public interest means (i) processing of personal data is necessary for health purposes such as public health and social protection and the management of health care services; (ii) processing of personal data is necessary for the control of communicable diseases and other serious threats to health; or (iii) processing of personal data is necessary by official authorities for achieving the purposes or objectives laid down by law.</li>
												<li>Transfer is necessary to respond to an emergency that threatens the life, health or safety of the data subject where such person is physically or legally incapable of giving consent, or</li>
												<li>Transfer is permitted under any other conditions which may be provided by the DPA in the future.</li>
											</ul>
											<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>If data is being processed outside Sri Lanka, it will be necessary to inform the data subjects about it in the respective consent forms and privacy/data protection notices of the controller, though it may not be practical or necessary to inform the exact location.</em></b></p>
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{/* the data protection authority */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_ten' onClick={() => accordion('chapter_ten')}>The Data Protection Authority<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-ten-brief">
								<p>The Data Protection Authority will be the main entity which would oversee the enforcement of the PDPA. This will be a brand-new entity and is likely to have an overarching monitoring capability over controllers and processors coming under their purview.</p>
								<p>The Role of the Data Protection Authority (DPA) is four-fold:</p>
								<img src={Chapter10GraphTwo} className="chapter-img" />
							</div>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_ten_one' onClick={() => accordion('chapter_ten_one')}>Special Directives And Compensation (Section 35)<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>If the DPA has reasons to believe that, based on a complaint or not, any controller or processor is violating or about to violate the PDPA or has violated the PDPA or any rules, regulation or guideline issued under the PDPA, then the DPA can initiate an inquiry to investigate. The procedure to be followed here is yet to be set out by the DPA.</p>
											<p>The DPA after giving an opportunity to the controller or processor concerned to explain themselves, may issue a directive, if need be, directing such controller or processor to stop violating the PDPA or refrain from the conduct that may lead to violating the PDPA. Such directives may also require a controller or processor to do such tasks which the DPA thinks appropriate to rectify the situation.</p>
											<p>In some cases, the DPA may direct a controller or processor to pay compensation to any aggrieved party which has been affected by the conduct of the controller or processor. The compensation is intended to cover any harm, loss or damage that has resulted from the violations of the PDPA by the controller or processor.</p>
											<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>It is necessary to remember that both controllers and processors are exposed to being issued a directive including directives related to payment of compensation to data subjects in the event of a violation. The maximum amount of compensation that can be awarded by the DPA is not given in the PDPA.</em></b></p>
											<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>As this is an administrative directive, if a controller or processor is not satisfied with a directive issued against them by the DPA, they may appeal against such decision before the Court of Appeal in Sri Lanka.</em></b></p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_ten_two' onClick={() => accordion('chapter_ten_two')}>Penalties (Sections 38 & 39)<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<p>Penalties may be handed out by the DPA in the event a controller or processor fails to comply with a directive issued by the DPA under section 35 of the PDPA.</p>
											<p>Each non-compliance may receive a penalty up to a maximum of LKR 10 million.</p>
											<p>If there is recurrent non-compliance, the penalty may be twice as what has been imposed for the previous violation which may even be over LKR 10 million.</p>
											<p>A penalty can be enforced by the DPA by making an application to the relevant Magistrate. The Magistrate’s Court may issue an order against the relevant controller or processor to recover such penalty.</p>
											<p>Imposition of a penalty by the DPA will not prevent a regulatory body from taking relevant regulatory measures under their respective laws against a controller or processor that comes under their regulatory purview. Such measures may include cancellation of licenses or other forms of penalties. Example: The Central Bank may take separate regulatory action against a licensed bank which has been given a penalty by the DPA.</p>
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_ten_three' onClick={() => accordion('chapter_ten_three')}>Factors Considered By The DPA When Imposing Penalties<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<img src={Chapter10GraphOne} className='chapter-img' />
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_ten_four' onClick={() => accordion('chapter_ten_four')}>Other Salient Powers<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<img src={Chapter10GraphThree} className="chapter-img" />
										</div>
									</li>
								</ul>
							</li>
							<li className="has-children">
								<div className="acnav__label acnav__label--level2" id='chapter_ten_five' onClick={() => accordion('chapter_ten_five')}>Other Salient Functions<span class="plus-icon">+</span></div>
								<ul className="acnav__list acnav__list--level3">
									<li>
										<div className="chapter-content-area">
											<img src={Chapter10GraphFour} className="chapter-img" />
											<p className='warning-txt'><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32z"/></svg><b><em>The DPA may issue licenses to controllers or processors requiring a license based on the recommendations of the advisory committee, for the purpose of regulating identity management and related services provided to data subjects under any statute. This may include licenses being issued to enable digital identity initiatives of the government.</em></b></p>
										</div>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{/* exemptions */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_eleven' onClick={() => accordion('chapter_eleven')}>Exemptions<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className="chapter-brief chapter-eleven-brief">
								<p>The PDPA recognises certain exemptions also known as derogations to the PDPA. That is to say, a controller or processor can refrain from complying with the PDPA in limited circumstances if they can establish to the DPA or Court of Law when questioned the following mandatory criteria:</p>
								<img src={Chapter11Graph} className='chapter-img' />
							</div>
						</ul>
					</li>
					{/* disclaimer */}
					<li className="has-children">
						<div className="acnav__label" id='chapter_twelve' onClick={() => accordion('chapter_twelve')}>Disclaimer and Notice<span class="plus-icon">+</span></div>
						<ul className="acnav__list acnav__list--level2">
							<div className='chapter-content-area'>
								<p>This Handbook is intended to give a general simplified summary of the Personal Data Protection Act No.9 of 2022.  The contents herein are not intended to be a substitute for legal or other professional advice. Neither the author nor the publishers shall be responsible for any inaccuracy, incompleteness, error or omission in this handbook or be liable for any damage or loss of any kind as a result of any use of or reliance on this handbook.</p>
								<p>Furthermore, the contents of this handbook are protected by copyright, or other forms of proprietary rights and may not be reproduced, republished or transmitted in any form or by any means, in whole or in part, without written permission of the author and the publisher.</p>
							</div>
						</ul>
					</li>
				</ul>
			</nav>
		</section>
    );
}