import './assets/stylesheets/Styles.scss';
import Tilt from 'react-parallax-tilt';
import ENLogo from './assets/images/logos/en-logo.png';
import FNFLogo from './assets/images/logos/fnf-logo.svg';
import ENLogoRound from './assets/images/logos/en-logo-round.png';
import FNFLogoRound from './assets/images/logos/fnf-logo-round.svg';
import Background from './Background';
import BannerTitle from './assets/images/titles/main-title.svg';
import BannerTitleMobile from './assets/images/titles/main-title-mobile.svg';
import PanelsTitle from './assets/images/titles/panels-title.svg';
import PanelsTitleMobile from './assets/images/titles/panels-title-mobile.svg';
import QuickPops from './assets/images/titles/quick-pops.svg';
import QuickPopsMobile from './assets/images/titles/quick-pops-mobile.svg';
import DownloadsTitle from './assets/images/titles/downloads-title.svg';
import DownloadsTitleMobile from './assets/images/titles/downloads-title-mobile.svg';
import ChaptersTitle from './assets/images/titles/chapters-title.svg';
import ChaptersTitleMobile from './assets/images/titles/chapters-title-mobile.svg';
import BannerSwirls from './assets/images/banners/banner-swirls.webp';
import BannerSwirlsMobile from './assets/images/banners/banner-swirls-mobile.webp';
import BannerIconDocument from './assets/images/banners/icons/banner-icon-document.svg';
import BannerIconGraph from './assets/images/banners/icons/banner-icon-graph.svg';
import BannerIconPadlock from './assets/images/banners/icons/banner-icon-padlock.svg';
import BannerIconPerson from './assets/images/banners/icons/banner-icon-person.svg';
import BannerIconShieldOne from './assets/images/banners/icons/banner-icon-shield-one.svg';
import BannerIconShieldTwo from './assets/images/banners/icons/banner-icon-shield-two.svg';
import BannerFigure from './assets/images/banners/banner-figure.webp';
import BannerThumbprint from './assets/images/banners/banner-thumbprint.webp';
import BannerPhone from './assets/images/banners/banner-phone.webp';
import BannerPanelOne from './assets/images/banners/panels/banner-panel-one.svg';
import BannerPanelTwo from './assets/images/banners/panels/banner-panel-two.svg';
import BannerPanelThree from './assets/images/banners/panels/banner-panel-three.svg';
import MobileBannerOne from './assets/images/banners/panels/mobile-panel-one.svg';
import MobileBannerTwo from './assets/images/banners/panels/mobile-panel-two.svg';
import MobileBannerThree from './assets/images/banners/panels/mobile-panel-three.svg';
import FadeInSection from './FadeInSection.js';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Hacker from './assets/images/graphics/hacker.webp';
import HackerTbl from './assets/images/graphics/hacker-table.webp';
import Computer from './assets/images/graphics/computer.webp';
import Note from './assets/images/graphics/note.webp';
import Squares from './assets/images/graphics/squares.webp';
import PanelArittha from './assets/images/panels/arittha.webp';
import PanelShenuka from './assets/images/panels/shenuka.webp';
import PanelJayantha from './assets/images/panels/jayantha.webp';
import PanelGehan from './assets/images/panels/gehan.webp';
import PanelSanduni from './assets/images/panels/sanduni.webp';
import PanelSujit from './assets/images/panels/sujit.webp';
import PanelOneSplashOne from './assets/images/panels/splash-one-panel-one.svg';
import PanelOneSplashTwo from './assets/images/panels/splash-two-panel-one.svg';
import PanelTwoSplashOne from './assets/images/panels/splash-one-panel-two.svg';
import PanelTwoSplashTwo from './assets/images/panels/splash-two-panel-two.svg';
import Arittha from './assets/images/highlights/arittha-wickramanayake.webp';
import AritthaTwo from './assets/images/highlights/arittha-wickramanayake-two.webp';
import Gehan from './assets/images/highlights/gehan-gunatilleke.webp';
import GehanTwo from './assets/images/highlights/gehan-gunatilleke-two.webp';
import Jayantha from './assets/images/highlights/jayantha-fernando.webp';
import SanuduniSujit from './assets/images/highlights/sanduni-sujit.webp';
import SanuduniSujitTwo from './assets/images/highlights/sanduni-sujit-two.webp';
import Shenuka from './assets/images/highlights/shenuka-jayalath.webp';
import SujitGehan from './assets/images/highlights/sujit-gehan.webp';
import ShenukaArittha from './assets/images/highlights/shenuka-arittha.webp';
import SwirlTwo from './assets/images/highlights/swirl-two.webp';
import SwirlFour from './assets/images/highlights/swirl-four.webp';
import SwirlFive from './assets/images/highlights/swirl-five.webp';
import SwirlSix from './assets/images/highlights/swirl-six.webp';
import SwirlEight from './assets/images/highlights/swirl-eight.webp';
import SwirlNine from './assets/images/highlights/swirl-nine.webp';
import SwirlTen from './assets/images/highlights/swirl-ten.webp';
import BookletCover from './assets/images/downloads/booklet-cover.webp';
import WhitepaperCover from './assets/images/downloads/whitepaper-cover.webp';
import Triangle from './assets/images/downloads/triangle.svg';
import DownloadIcon from './assets/images/downloads/download-icon.svg';
import { saveAs } from "file-saver";
import PDFBooklet from './assets/pdf/pdpa-booklet.pdf';
import PDFWhitepaper from './assets/pdf/pdpa-whitepaper.pdf';

export default function App() {

  const downloadBooklet = () => {
    saveAs(PDFBooklet, "PDPA: A Complete Guide.pdf");
  }

  const downloadWhitepaper = () => {
    saveAs(PDFWhitepaper, "PDPA: Policy Paper.pdf");
  }

  return (
    <>
      <section>
        <div className='container'>
          <Background />
          {/* Banner Desktop */}
          <div className='banner'>
            <img src={BannerSwirls} className='background-elements banner-swirls' />
            <img src={BannerFigure} className='background-elements banner-figure' />
            <img src={BannerThumbprint} className='background-elements banner-thumbprint' />
            <img src={BannerIconDocument} className='background-elements banner-icon-document' />
            <img src={BannerIconGraph} className='background-elements banner-icon-graph' />
            <img src={BannerIconPadlock} className='background-elements banner-icon-padlock' />
            <img src={BannerIconPerson} className='background-elements banner-icon-person' />
            <img src={BannerIconShieldOne} className='background-elements banner-icon-shield-one' />
            <img src={BannerIconShieldTwo} className='background-elements banner-icon-shield-two' />
            <img src={BannerPhone} className='background-elements banner-phone' />
            <div className="top-logos">
              <div className="en-logo"><a href="https://economynext.com/" target="_blank"><img src={ENLogo} /></a></div>
              <div className="fnf-logo"><a href="https://www.freiheit.org/" target="_blank"><img src={FNFLogo} /></a></div>
            </div>
            <div className="banner-panel-one">
              <img src={BannerPanelOne} className='background-elements' />
              <div className="panel-one-answer">The Personal Data Protection Act (PDPA), is a new law that aims to facilitate growth and innovation of the digital economy while protecting the rights of individuals</div>
            </div>
            <div className="banner-panel-two">
              <img src={BannerPanelTwo} className='background-elements' />
              <div className="panel-two-answer">Any information that can either directly or indirectly identify a human being is considered as personal data under the act</div>
            </div>
            <div className="banner-panel-three">
              <img src={BannerPanelThree} className='background-elements' />
              <div className="panel-three-answer">All personal data that continues to be processed when the PDPA is in effect including data held in physical form or in digital form</div>
            </div>

            <div class="line">
              <div class="scanner"></div>
            </div>
            {/* <div className="background-elements title-text">Personal<br></br> Data Protection<div class="title-act">ACT No.9 OF 2022</div></div> */}
            <div className="background-elements title-text"><img src={BannerTitle} width="100%" /><div class="title-act">Personal Data Protection Act No.09 of 2022</div></div>
          </div>
          {/* Banner Mobile */}
          <div className="banner-mobile">
            <img src={BannerSwirlsMobile} className='background-elements banner-swirls-mobile' />
            <img src={BannerFigure} className='background-elements banner-figure' />
            <img src={BannerIconDocument} className='background-elements banner-icon-document' />
            <img src={BannerIconPadlock} className='background-elements banner-icon-padlock' />
            <img src={BannerIconPerson} className='background-elements banner-icon-person' />
            <div className='background-elements banner-thumbprint'>
              <img src={BannerThumbprint} />
              <div class="line">
                <div class="scanner"></div>
              </div>
            </div>
            <div className="top-logos">
              <div className="en-logo"><a href="https://economynext.com/" target="_blank"><img src={ENLogoRound} /></a></div>
              <div className="fnf-logo"><a href="https://www.freiheit.org/" target="_blank"><img src={FNFLogoRound} /></a></div>
            </div>
            <div className="background-elements title-text"><img src={BannerTitleMobile} width="100%" /><div class="title-act">Personal Data Protection <br></br>Act No.09 of 2022</div></div>
          </div>
          <FadeInSection>
            <div className="separation-line"></div>
          </FadeInSection>
          {/* Questions Mobile */}
          <div className='questions-container'>
              <Popup trigger={
                <div className='first-question'>
                  <img src={MobileBannerOne} />
                </div> } modal nested >
                  {close => (
                    <div>
                      <div className="panel-one-answer">The Personal Data Protection Act (PDPA), is a new law that aims to facilitate growth and innovation of the digital economy while protecting the rights of individuals</div>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
              </Popup>
              <Popup trigger={
                <div className='second-question'>
                  <img src={MobileBannerTwo} />
                </div> } modal nested >
                    {close => (
                      <div>
                        <div className="panel-two-answer">Any information that can either directly or indirectly identify a human being is considered as personal data under the act</div>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
              </Popup>
              <Popup trigger={
                <div className='third-question'>
                  <img src={MobileBannerThree} />
                </div>} modal nested >
                  {close => (
                    <div>
                      <div className="panel-three-answer">All personal data that continues to be processed when the PDPA is in effect including data held in physical form or in digital form</div>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
              </Popup>
          </div>
          {/* Discussions */}
          <FadeInSection>
            <div className="discussions-separation"></div>
          </FadeInSection>
          <div className="discussions-container">
            <img src={HackerTbl} className='background-elements hacker-tbl' />
            <img src={Hacker} className='background-elements hacker' />
            <img src={Computer} className='background-elements discussions-computer' />
            <img src={Note} className='background-elements discussions-note' />
            <img src={Squares} className='background-elements discussions-squares' />
            <FadeInSection>
              <div className="discussions-title discussions-title-desktop"><img src={PanelsTitle} /></div>
              <div className="discussions-title discussions-title-mobile"><img src={PanelsTitleMobile} /></div>
            </FadeInSection>
            <FadeInSection>
              <div className='discussions-strap'>Expert panels discuss data protection, the PDPA and its wide ranging implications</div>
            </FadeInSection>
            <div className="panels">
              <FadeInSection>
                  <Popup trigger={
                    <div className="panel-one">
                      <div className="panel-title">Sri Lanka's New<br></br> Data Privacy Law and its Impact</div>
                      <div className="panel-members">
                        <div className="background-elements panel-one-name-one">Arittha <p>Wikramanayake</p></div>
                        <div className="background-elements panel-one-name-two">Shenuka <p>Jayalath</p></div>
                        <div className="background-elements panel-one-name-three">Jayantha <p>Fernando</p></div>
                        <img src={PanelArittha} className='panel-one-img-one' />
                        <div className="panel-img-div"></div>
                        <img src={PanelShenuka} className='panel-one-img-two' />
                        <div className="panel-img-div"></div>
                        <img src={PanelJayantha} className='panel-one-img-three' />
                        <img src={PanelOneSplashOne} className='background-elements panel-bg panel-one-bg' />
                        <img src={PanelOneSplashTwo} className='background-elements panel-bg hover-splash hover-splash-panel-one' />
                      </div>
                      <div className='play-btn play-btn-panel-one'>
                        <span class="text">Play Video</span>
                        <div class="icon-container">
                          <div class="icon icon--left">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                          </div>
                          <div class="icon icon--right">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/788899665?h=6ab7fea620&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
              </FadeInSection>
              <FadeInSection>
                  <Popup trigger={
                    <div className="panel-two">
                      <div className="panel-title">Data Privacy Law and its Intersection<br></br> with Organisations and Society</div>
                      <div className="panel-members">
                        <div className="background-elements panel-two-name-one">Sujit <p>Christy</p></div>
                        <div className="background-elements panel-two-name-two">Sanduni <p>Wickramasinghe</p></div>
                        <div className="background-elements panel-two-name-three">Gehan <p>Gunatilleke</p></div>
                        <img src={PanelGehan} className='panel-two-img-one' />
                        <div className="panel-img-div"></div>
                        <img src={PanelSanduni} className='panel-two-img-two' />
                        <div className="panel-img-div"></div>
                        <img src={PanelSujit} className='panel-two-img-three' />
                        <img src={PanelTwoSplashTwo} className='background-elements panel-bg panel-two-bg' />
                        <img src={PanelTwoSplashOne} className='background-elements panel-bg hover-splash hover-splash-panel-two' />
                      </div>
                      <div className='play-btn play-btn-panel-two'>
                        <span class="text">Play Video</span>
                        <div class="icon-container">
                          <div class="icon icon--left">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                          </div>
                          <div class="icon icon--right">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/786506952?h=cf4350a39d&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
              </FadeInSection>
            </div>
          </div>
          {/* Highlights */}
          <div className="highlights-container highlights-desktop">
            <div className="highlights-title-section">
              <FadeInSection>
                <div className='highlights-title'><img src={QuickPops} /></div>
              </FadeInSection>
              <FadeInSection>
                <div className="highlights-strap">Video highlights discussing Sri Lanka’s personal data protection regime, its implementation and impact</div>
              </FadeInSection>
            </div>
            <FadeInSection>
              <div className="highlights-row">
                {/* how data privacy laws add value to companies */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-yellow'>
                        <img src={SwirlFour} className='highlight-img highlight-swirl-img' />
                        <img src={Arittha} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Arittha Wikramanayake</div>
                      <div className='highlight-divider'></div>
                      <div className='highlight-title'>How Data Privacy Laws Add Value to Companies</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/783172246?h=7fde322280&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* how the data privacy law was drafted */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-pink'>
                        <img src={SwirlTwo} className='highlight-img highlight-swirl-img ' />
                        <img src={Jayantha} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='jayantha-svg'><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Jayantha Fernando</div>
                      <div className='highlight-title'>How the Data Privacy Law was Drafted</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/783172588?h=a657adfdda&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* responding to data privacy laws in organisations */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-blue'>
                        <img src={SwirlFour} className='highlight-img highlight-swirl-img' />
                        <img src={ShenukaArittha} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='arittha-shenuka-svg'><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Arittha Wikramanayake &<br></br>Shenuka Jayalath</div>
                      <div className='highlight-title'>Responding to Data Privacy Laws in Organisations</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/783173070?h=bce0aa98e6&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* impact assessment and privacy by design */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-pink-faded'>
                        <img src={SwirlSix} className='highlight-img highlight-swirl-img' />
                        <img src={Shenuka} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Shenuka Jayalath</div>
                      <div className='highlight-title'>Impact Assessment and Privacy by Design</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/783173527?h=b0b4ab7c7d&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* data privacy in the face of changing business models */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-purple'>
                        <img src={SwirlFive} className='highlight-img highlight-swirl-img' />
                        <img src={AritthaTwo} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Arittha Wikramanayake</div>
                      <div className='highlight-title'>Data Privacy in the Face of Changing Business Models</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/783171793?h=6f6f15b448&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="highlights-row">
                {/* a culture of personal data protection */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-purple'>
                        <img src={SwirlSix} className='highlight-img highlight-swirl-img ' />
                        <img src={SujitGehan} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Sujit Christy &<br></br>Gehan Gunatilleke</div>
                      <div className='highlight-title'>A Culture of Personal Data Protection</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/786511702?h=e078b33903&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* data protection and attracting investment */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-pink-faded'>
                        <img src={SwirlTen} className='highlight-img highlight-swirl-img' />
                        <img src={SanuduniSujit} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='sujit-sanduni-svg'><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Sujit Christy &<br></br>Sanduni Wickramasinghe</div>
                      <div className='highlight-title'>Data Protection and Attracting Investment</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/786512143?h=aa9617c3b6&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* can privacy laws be abused? */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-yellow'>
                        <img src={SwirlEight} className='highlight-img highlight-swirl-img' />
                        <img src={Gehan} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='gehan-svg'><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Gehan Gunatilleke</div>
                      <div className='highlight-title'>Can Privacy Laws be Abused?</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/786512643?h=721418df35&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* making the rules that govern data */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-pink'>
                        <img src={SwirlNine} className='highlight-img highlight-swirl-img' />
                        <img src={SanuduniSujitTwo} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Sujit Christy &<br></br>Sanduni Wickramasinghe</div>
                      <div className='highlight-title'>Making the Rules that Govern the Data</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/786512898?h=f1214c7941&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
                {/* the concept of informed consent */}
                <Tilt scale={1.1}>
                  <Popup trigger={
                    <div className='highlight'>
                      <div className='highlight-img-container highlight-bg-clr-blue'>
                        <img src={SwirlTen} className='highlight-img highlight-swirl-img' />
                        <img src={GehanTwo} className='highlight-img highlight-guest-img background-elements' />
                      </div>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className='gehan-svg'><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                      <div className='highlight-member-name'>Gehan Gunatilleke</div>
                      <div className='highlight-title'>The Concept of Informed Consent</div>
                    </div>
                  }
                  modal
                  nested >
                    {close => (
                      <div>
                        <div style={{padding:'100% 0 0 0', position:'relative'}}>
                          <iframe src="https://player.vimeo.com/video/786506200?h=ed6b8abac1&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                        </div>
                        <script src="https://player.vimeo.com/api/player.js"></script>
                        <a className="close" onClick={close}>
                          &times;
                        </a>
                      </div>
                    )}
                  </Popup>
                </Tilt>
              </div>
            </FadeInSection>
          </div>
          {/* Highlights Mobile */}
          <FadeInSection>
            <div className="highlights-container highlights-mobile">
              <div className="highlights-title-section">
                <div className='highlights-title'><img src={QuickPopsMobile} /></div>
                <div className="highlights-strap">Video highlights discussing Sri Lanka’s personal data protection regime, its implementation and impact</div>
              </div>
              <div className="highlights-row">
                {/* how data privacy laws add value to companies */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-yellow'>
                      <img src={SwirlFour} className='highlight-img highlight-swirl-img' />
                      <img src={Arittha} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Arittha Wikramanayake</div>
                    <div className='highlight-divider'></div>
                    <div className='highlight-title'>How Data Privacy Laws Add Value to Companies</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/783172246?h=7fde322280&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
                {/* how the data privacy law was drafted */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-pink'>
                      <img src={SwirlTwo} className='highlight-img highlight-swirl-img ' />
                      <img src={Jayantha} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Jayantha Fernando</div>
                    <div className='highlight-title'>How the Data Privacy Law was Drafted</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/783172588?h=a657adfdda&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
              </div>
              <div className="highlights-row">
                {/* responding to data privacy laws in organisations */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-blue'>
                      <img src={SwirlFour} className='highlight-img highlight-swirl-img' />
                      <img src={ShenukaArittha} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Shenuka Jayalath & Arittha Wikramanayake</div>
                    <div className='highlight-title'>Responding to Data Privacy Laws in Organisations</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/783173070?h=bce0aa98e6&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
                {/* impact assessment and privacy by design */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-pink-faded'>
                      <img src={SwirlSix} className='highlight-img highlight-swirl-img' />
                      <img src={Shenuka} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Shenuka Jayalath</div>
                    <div className='highlight-title'>Impact Assessment and Privacy by Design</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/783173527?h=b0b4ab7c7d&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
              </div>
              <div className="highlights-row">
                {/* data privacy in the face of changing business models */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-purple'>
                      <img src={SwirlFive} className='highlight-img highlight-swirl-img' />
                      <img src={AritthaTwo} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Arittha Wikramanayake</div>
                    <div className='highlight-title'>Data Privacy in the Face of Changing Business Models</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/783171793?h=6f6f15b448&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
                {/* a culture of personal data protection */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-purple'>
                      <img src={SwirlSix} className='highlight-img highlight-swirl-img ' />
                      <img src={SujitGehan} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Sujit Christy & Gehan Gunatilleke</div>
                    <div className='highlight-title'>A Culture of Personal Data Protection</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/786511702?h=e078b33903&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
              </div>
              <div className="highlights-row">
                {/* data protection and attracting investment */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-pink-faded'>
                      <img src={SwirlTen} className='highlight-img highlight-swirl-img' />
                      <img src={SanuduniSujit} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Sanduni Wickramasinghe & Sujit Christy</div>
                    <div className='highlight-title'>Data Protection and Attracting Investment</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/786512143?h=aa9617c3b6&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
                {/* can privacy laws be abused? */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-yellow'>
                      <img src={SwirlEight} className='highlight-img highlight-swirl-img' />
                      <img src={Gehan} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Gehan Gunatilleke</div>
                    <div className='highlight-title'>Can Privacy Laws be Abused?</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/786512643?h=721418df35&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
              </div>
              <div className="highlights-row">
                {/* making the rules that govern data */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-pink'>
                      <img src={SwirlNine} className='highlight-img highlight-swirl-img' />
                      <img src={SanuduniSujitTwo} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Sanduni Wickramasinghe & Sujit Christy</div>
                    <div className='highlight-title'>Making the Rules that Govern the Data</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/786512898?h=f1214c7941&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
                {/* the concept of informed consent */}
                <Popup trigger={
                  <div className='highlight'>
                    <div className='highlight-img-container highlight-bg-clr-blue'>
                      <img src={SwirlTen} className='highlight-img highlight-swirl-img' />
                      <img src={GehanTwo} className='highlight-img highlight-guest-img background-elements' />
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M188.3 147.1C195.8 142.8 205.1 142.1 212.5 147.5L356.5 235.5C363.6 239.9 368 247.6 368 256C368 264.4 363.6 272.1 356.5 276.5L212.5 364.5C205.1 369 195.8 369.2 188.3 364.9C180.7 360.7 176 352.7 176 344V167.1C176 159.3 180.7 151.3 188.3 147.1V147.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>
                    <div className='highlight-member-name'>Gehan Gunatilleke</div>
                    <div className='highlight-title'>The Concept of Informed Consent</div>
                  </div>
                }
                modal
                nested >
                  {close => (
                    <div>
                      <div style={{padding:'100% 0 0 0', position:'relative'}}>
                        <iframe src="https://player.vimeo.com/video/786506200?h=ed6b8abac1&badge=0&autopause=0&player_id=0&app_id=58479" frameborder="0" allow="fullscreen; picture-in-picture" allowfullscreen style={{position:'absolute',top:'0', left:'0', width:'100%', height:'100%'}} title="data-protection-panel-one.mp4"></iframe>
                      </div>
                      <script src="https://player.vimeo.com/api/player.js"></script>
                      <a className="close" onClick={close}>
                        &times;
                      </a>
                    </div>
                  )}
                </Popup>
              </div>
            </div>
          </FadeInSection>
          {/* Downloads */}
          <FadeInSection>
            <div className='downloads-section'>
              <div className='downloads-title downloads-desktop-title'><img src={DownloadsTitle} /></div>
              <div className='downloads-title downloads-mobile-title'><img src={DownloadsTitleMobile} /></div>
              <div className="downloads-strap">Download the policy and PDPA guide that explains the policy, principles and how the personal data protection law works</div>
              <div className="download-row">
                <div className="download-booklet" onClick={downloadBooklet}>
                  <div className='downloads-text downloads-mobile-txt complete-guide'>PDPA: A Complete Guide</div>
                  <div className='downloads-booklet-description'>A handbook to guide you through Sri Lanka’s Personal Data Protection Act No.9 of 2022</div>
                  <img src={Triangle} className='downloads-triangle-booklet' />
                  <img src={BookletCover} className='booklet-cover-img background-elements' />
                  <div className='background-elements download-icon'><img src={DownloadIcon} /></div>
                </div>
                <div className="download-whitepaper" onClick={downloadWhitepaper}>
                  <div className='downloads-text downloads-desktop-txt policy-paper'>White Paper</div>
                  <div className='downloads-whitepaper-description'>Personal Data Protection Act No.9 of 2022, its rationale, challenges and likely impact on organisations and people</div>
                  <img src={Triangle} className='downloads-triangle-whitepaper' />
                  <img src={WhitepaperCover} className='whitepaper-cover-img background-elements' />
                  <div className='background-elements download-icon'><img src={DownloadIcon} /></div>
                </div>
              </div>
            </div>
          </FadeInSection>
          <div className="chapters-title chapters-title-desktop"><img src={ChaptersTitle} /></div>
          <div className="chapters-title chapters-title-mobile"><img src={ChaptersTitleMobile} /></div>
          <div className='chapter-strap'>Click any chapter to access the ‘PDPA: complete guide’ content. No downloads here</div>
        </div>
      </section>
    </>
  );
}