import React from 'react';
import ReactDOM from 'react-dom/client';
import Main from './Main';
import Chapters from './Chapters';
import Footer from './Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Main />
    <Chapters />
    <Footer />
  </React.StrictMode>
);